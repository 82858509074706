@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
.fontmaaray{
    font-family: 'Almarai', sans-serif;
}
/* Modal container */
 
  /* Modal overlay */
  .modalshare .modal-dialog {
   transition: transform 0.3s ease-out;
   /* transform: translateY(0, 50%); */
    /* Initial position offscreen, bottom of the screen */
  }
  
  .modal.show .modal-dialog {
   transform: translate(0, 0); /* Move modal to center position */
  }
  
  /* Modal content */
  .modal-dialog {
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none;
   width: 90%;
  }
  
  .modal-content {
   /* position: fixed; */
   display: flex;
   justify-content: center;
   /* flex-direction: column; */
    width: 100% !important;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border: none;
   border-radius: 0.25rem;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); 
   /* outline: 0;
   transform: translateY(50%); */
    /* Initial position offscreen, bottom of the modal */
   /* transition: transform 0.3s ease-out; */
  
  }
  
  .modalshare.show .modal-content {
   transform: translateY(0); /* Slide up the modal from the bottom */
  }
  
  /* Modal header */
  .modal-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0.5rem 1rem;
   border-bottom: none;
   background-color: #f8f9fa;
  }
  .modal-title {
   margin: 0;
  }
  
  /* Modal body */
  .modal-body {
   flex: 1;
   padding: 1rem;
   /* height: 50% !important; */
   font-size: 1rem;
  }

  @media only screen and (min-width: 769px){
    .iframwidth{
      width: 490px !important;
    }
     
  
    .modalshare {
        /* Hide the modal by default */
       position: fixed;
       top: 0;
       left: 28%;
       right: 0;
       bottom: 0;
       z-index: 199950;
       width: 43%;
       height: 100%;
       overflow: hidden;
       outline: 0;
       /* background-color: #2e2e2e6c; */
       display: flex;
       justify-content: center ;
      } 
  }
  @media only screen and (max-width: 430px) and (min-width: 280px){
    .iframwidth{
      width: 341px !important
    }
     

    .modalshare {
        /* Hide the modal by default */
       position: fixed;
       top: 0;
       left: 6%;
       right: 0;
       bottom: 0;
       z-index: 199950;
       width: 90%;
       height: 100%;
       overflow: hidden;
       outline: 0;
       /* background-color: #2e2e2e6c; */
       display: flex;
       justify-content: center ;
      }
  }