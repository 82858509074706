@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
.fontmaaray{
    font-family: 'Almarai', sans-serif;
}
.bord{
    border: 2px;
    border-color:red ;
}

.flexx{
    display: flex;
}
  .btnwidth{
    text-align: center;
    background-color: #ff9800;
    width: 11%;
  }
.paymentbutton {
  height: 80px;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
     -moz-appearance: checkbox;
          appearance: checkbox;
  display: inline-block;
  width: auto;
}
.inputshow{
  display: none;
}
.widthofhr{
  width: 100%;
}

.btncolor{
  background-color: #ff9800;

}
.widthofmezza{
  width: 80px;
  height: 80px;
  object-fit: scale-down;
}
.hide{
  display: none;
}
.submitheight{
  height: 90px;
}
.hidedeliveredinput{
  display: none;
}
#gifwave{
  display: none;
}
.roundedtitle{
  border-radius:5px;

}
.widthofmezza:focus{
  border: 1px solid;
}

@media only screen and (max-width: 430px) and (min-width: 280px){
  .submitheightdes{
    height: 90px;
    display: none;
  }
  .submitheightmob{
    height: 90px;
    display: block;

  }
}
@media only screen and (min-width: 769px){
  .submitheightdes{
    height: 90px;
    display: block;
  }
  .submitheightmob{
    height: 90px;
    display: none;

  }

}
