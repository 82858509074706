        @import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
        .fontmaaray{
            font-family: 'Almarai', sans-serif;
        }
        .roundedtitle{
          border-radius:5px;

        }
        
        .swal2-checkbox{
          direction: rtl !important;
        }
        .swal2-close{
          position: absolute;
          left: 0;
        }
        #orderprint2{
          width: 303px;
          display: none;
        }
        .swal2-title{
          font-family: 'Almarai', sans-serif;
        }
.shadow{
  box-shadow: 0px 0rem 0.5rem 0px rgba(0, 0, 0, 0.15) !important;
}
#orderprint2{
  width: 303px;
  display: none;
}
.iframwidth{
  width: 341px !important
}
.swal2-html-container{
  width: 99%;
    margin-left: 0px !important;
}
/* .pop-parent{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #2e2e2e6c;
  display: flex;
  justify-content: center;
  align-items: center;
} */
#popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* height:511px; */
  z-index: 99;
  background-color: white;
}

.Items {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 4%;
  overflow: auto;
  justify-content: center;
  font-family: 'Almarai', sans-serif;
}

.ItemName {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 150px;
}

.ItemCard {
  display: flex;
  position:relative;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 150px;
  margin-bottom: 3%;
}

.CardCss {
  position:relative;
  justify-content: center;
}

.btnright{
  margin-left: 23%;
  height: 85%;
  border-radius: 0px 5px 5px 0px;  
}

.borderbtn{
  border-radius: 5px 0px 0px 5px;
  height: 85%;


}
.positionnumber{
  text-align: right;
}
table tr {
  border-bottom: 0.1px solid #C5C5C5;
}
table {
  border-collapse: separate;
  border-spacing: 8px;
}
.textbold{
  font-weight: bold;

}
.divbody{
  display: flex;
  gap: 3%;
  padding-top: 3px;
  justify-content: center;
}
.Price{
  padding-right: 30px ; 
}
.Qty{
  display: flex;
  width:20px;
}
.borderradius{
  border: 0.2px ridge white;
  
}

.fontsize{
  font-size:x-small;
  padding-right: 2px;
}

.flag:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 0px solid white;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.VoiceRecord {
  width: 300px;
  }

.jsutprop{
  display:none
}
#price{
  color: #fea500;
}
.margespan{
  margin-left: 31%;

}

.widthofhr{
  width: 80%;
}
.hrline{
  width: 100%;
}
.ban{
  width: 100%;

}

input[type=checkbox] {
  background:transparent;
  appearance: none;
  border:0;
}
.makebord{
  border: 1px solid;
  border-width: 2px;
}


/* Modal container */
.modal-yasser {
  /* Hide the modal by default */
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 199950;
 width: 100%;
 height: 100%;
 overflow: hidden;
 outline: 0;
 background-color: #2e2e2e6c;
 display: flex;
 justify-content: center ;
}

/* Modal overlay */
.modal-yasser .modal-dialog {
 transition: transform 0.3s ease-out;
 /* transform: translateY(0, 50%); */
  /* Initial position offscreen, bottom of the screen */
}

.modal.show .modal-dialog {
 transform: translate(0, 0); /* Move modal to center position */
}

/* Modal content */
.modal-dialog {
 position: relative;
 width: auto;
 margin: 0.5rem;
 pointer-events: none;
 width: 90%;
}

.modal-content {
 /* position: fixed; */
 display: flex;
 justify-content: center;
 /* flex-direction: column; */
  width: 100% !important;
 pointer-events: auto;
 background-color: #fff;
 background-clip: padding-box;
 border: none;
 border-radius: 0.25rem;
 box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); 
 /* outline: 0;
 transform: translateY(50%); */
  /* Initial position offscreen, bottom of the modal */
 /* transition: transform 0.3s ease-out; */

}

.modal-yasser.show .modal-content {
 transform: translateY(0); /* Slide up the modal from the bottom */
}

/* Modal header */
.modal-header {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 0.5rem 1rem;
 border-bottom: none;
 background-color: #f8f9fa;
}
.modal-title {
 margin: 0;
}

/* Modal body */
.modal-body {
 flex: 1;
 padding: 1rem;
 /* height: 50% !important; */
 font-size: 1rem;
}
#orderprint2{
  width: 547px;
  display: none;
}
@media screen and (min-width:  769px)   {
  .orderprint2{
    width:280px;
   }
  .checktrue{
    margin-left: 115%;
  }
  #printabledivdesktop{
    display: none;
  }
  .flag {
    width: 43px;
    height: 48px;
    box-sizing: content-box;
    padding-top: 5px;
    position: relative;
    color: white;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    margin-left: -6%;
    margin-top: -2.7%;

  }
 
  .btnprientdesktop{
    display: flex;
   }
   .btnprientmobile{
    display: none;
   }
   .printablediv{
    width:280px;
   }
   
    .itemindesktop{
      display: block;
    }
    .iteminmobile{
      display: none;
    }
 
  .icnm {
    font-size: 1.6rem !important;
  
 }
 .modal-dialog {
  width: 550px;
 } 
 .modal-body{
  height: 400px !important;
  font-size: 1.3rem;

}
.btnprientdesktop{
  display: flex;
 }
 .btnprientmobile{
  display: none;
 }
}


@media only screen and (max-width: 430px) and (min-width: 280px){
  .checktrue{
    margin-left: 63%;
  }
  #printabledivdesktop{
    display: none;
  }
  .btnprientdesktop{
    display: none;
   }
   .btnprientmobile{
    display: flex;
   }
  
  .flag {
    width: 43px;
    height: 48px;
    box-sizing: content-box;
    padding-top: 5px;
    position: relative;
    color: white;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    margin-left: 0%;
    margin-top:-10%;
  }
 

  .iteminmobile{
    display: block;
  }
  .itemindesktop{
    display: none;
  }
  .QtyInMobile{
    padding: 0px;
  }
  .userinsaleman{
    margin-top:6px;
    margin-right: -9px;
  }
 
  .hrmobile{
  display: none;
 }
  .popupsalesman{
    position: fixed;
    top: 25%;
    right: 7%;
    z-index: 1;
    height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;

  }
}