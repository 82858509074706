@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
.fontmaaray{
    font-family: 'Almarai', sans-serif;
}
.custombuttonsweet{
  width: 300px;
}
.customtitlesweet{
  font-size: 28px !important;
}
#swal2-html-container{
  font-size: 15px !important;
}
.voice{
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    justify-content: center;
    background-color: #dddddd;

}
.heightvoicerecord{
    width: 200px;
    height: 50px;
}



.hightextarea{
  height: 140px;
}
.widthborder{
  width: 90%;
  
 
}
.deletecolorbutton{
  color: #dc3545;
}
.border:focus{
  border: 1px solid;
}