@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
.fontmaaray{
    font-family: 'Almarai', sans-serif;
}
.bord{
    border: 2px;
    border-color:red ;
}
.roundedtitle{
  border-radius:5px;

}

.flexx{
    display: flex;
}
  .btnwidth{
    text-align: center;
    width: 16%;
    background-color: #ff9800;
    margin-right: 5%;
    margin-top: 0.4%;
    
  }
.text-area-width{
  width: 73%;
    margin-right: 3%;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
     -moz-appearance: checkbox;
          appearance: checkbox;
  display: inline-block;
  width: auto;
}
.inputshow{
  display: none;
}
.widthofhr{
  width: 30%;
}
.textmarg{
  margin-left: 20px;
}
.btncolor{
  background-color: #ff9800;

}
.widthofmezza{
  width: 80px;
  height: 80px;
  object-fit: scale-down;
}
.hide{
  display: none;
}
.hidedeliveredinput{
  display: none;
}
#gifwave{
  display: none;
}

@media only screen and (min-width: 769px) and (max-width: 1920px){
.logo-imagesNomazine{
  max-width: 28%;
  object-fit: scale-down;

  
}

}
@media only screen and (max-width: 430px) and (min-width: 280px){
  .logo-imagesNomazine{
    max-width: 90%;
    object-fit: scale-down;
  
    
  }
}



