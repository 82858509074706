.carouselheigh{
    background-size:contain;
     height:300px;
}
.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 500px;
    width: auto;
}