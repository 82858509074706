

.logo-images{
max-width: 114px;
object-fit: scale-down;
}
.magazine-title {
    text-align: right;
    margin-right: 30px;
    height: 40px !important;
    }

    .card-title {
        margin-bottom: var(--bs-card-title-spacer-y);
        font-family: 'Almarai', sans-serif;}
        .text-header {
            --bs-text-opacity: 1;
            font-weight: bold;
            font-size: 27px !important;
            letter-spacing: -1px;
        }


        @media only screen and (max-width: 430px) and (min-width: 280px){

            .headinmobile{
                margin-top: 11%;
            }
        }
 